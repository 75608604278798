import type { NextPage } from 'next';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { initServerClient } from '~/client/apollo/initServer';
import { AccountLayout, Login } from '@quotalogic/account';

export const getServerSideProps: GetServerSideProps = async ({ req, locale }) => {
  const uuid = req.cookies?.auth;
  const appName = process.env.APP_NAME;

  if (uuid) {
    const { headers } = req;
    const client = await initServerClient(headers);

    if (client) {
      return {
        redirect: {
          permanent: false,
          destination: '/',
        },
      };
    }
  }

  return {
    props: {
      ...(locale ? await serverSideTranslations(locale) : {}),
      appName,
    },
  };
};

const Index: NextPage<{ appName: string }> = ({ appName }) => (
  <AccountLayout
    name={appName}
    website="quotcat.ru"
  >
    <Login appName="Quotcat" />
  </AccountLayout>
);

export default Index;
